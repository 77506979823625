.gradient-mask-container {
  position: relative;
}

.gradient-mask-container::before,
.gradient-mask-container::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100px;
  pointer-events: none;
  z-index: 10;
}

.gradient-mask-container::before {
  left: 0;
  background: linear-gradient(to right, #fff, transparent);
}

.gradient-mask-container::after {
  right: 0;
  background: linear-gradient(to left, #fff, transparent);
}

.hide-left-gradient::before {
  display: none;
}

.hide-right-gradient::after {
  display: none;
}
